export enum ScoreDataDefault {
  TOTALSCORE = 'totalScore',
  HIGHSCORE = 'highscore',
  MAP = 'map',
  LEVEL = 'level',
  STARS = 'stars',
  PLAYTIME = 'playtime'
}

export enum ScoreGameTypes {
  INFINITE = 'infinite',
  LEVELS = 'levels'
}

export enum ScoreResultTypes {
  FAILED = 'failed',
  PASSED = 'passed',
  FINISHED = 'finished'
}

export interface ScoreConfig {
  type: ScoreGameTypes,
  stats: string[]
}

export interface ScoreDataEntry {
  key: string,
  value: number | string,
  isStat?: boolean
}
