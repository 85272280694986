import { ISaveFileLevel } from "@/interfaces/ISaveFile";
import { ScoreConfig, ScoreDataDefault, ScoreDataEntry, ScoreGameTypes } from "@/interfaces/ISessionFile";
import globalSaveFile from "./GlobalSaveFile";

class SessionSaveFile {
  type: ScoreGameTypes = ScoreGameTypes.INFINITE;
  entries: ScoreDataEntry[] = [];
  defaultData: ScoreDataDefault[] = [ScoreDataDefault.TOTALSCORE, ScoreDataDefault.HIGHSCORE];
  levelData: ScoreDataDefault[] = [ScoreDataDefault.MAP, ScoreDataDefault.LEVEL, ScoreDataDefault.STARS];

  create(config: ScoreConfig) {
    this.type = config.type;

    /** DEFAULT: Add default data */
    for (const data of this.defaultData) {
      this.addEntry(data, 0);
    }

    /** LEVELS: Add level-only data */
    if (config.type === ScoreGameTypes.LEVELS) {
      for (const data of this.levelData) {
        this.addEntry(data, 0);
      }
    }

    /** STATS: Add optional stats data */
    for (const stat of config.stats) {
      this.addEntry(stat, 0, true);
    }

    return this.entries;
  }

  addEntry(key: string, value: number | string, isStat = false) {
    const item = this.getEntry(key);
    if (!item) {
      this.entries.push({ key, value, isStat })
    }
  }

  getAll() {
    return this.entries;
  }

  getType() {
    return this.type;
  }

  getEntry(key: string) {
    const item = this.entries.find(item => item.key === key);
    return item;
  }

  getValue(key: string) {
    const item = this.entries.find(item => item.key === key);
    return item?.value || 0;
  }

  updateValue(key: string, value: number | string) {
    const item = this.getEntry(key);
    if (item) item.value = value;
    return item?.value || 0;
  }

  incrementValue(key: string, value: number) {
    const item = this.getEntry(key);
    if (item && !isNaN(+item.value)) (item.value as number) += value;
  }

  clear() {
    this.entries = [];
  }

  syncGlobalSaveFile() {
    const gameType = this.getType();
    const globalSaveFileSource = globalSaveFile.get();
    const totalScore = parseInt(`${this.getValue(ScoreDataDefault.TOTALSCORE)}`);
    const highscore = parseInt(`${this.getValue(ScoreDataDefault.HIGHSCORE)}`);
    const globalSaveFileOverall = globalSaveFileSource.game.overall;

    if (gameType==ScoreGameTypes.INFINITE) {
        if (globalSaveFileOverall.totalScore < totalScore) globalSaveFileOverall.totalScore = totalScore;
        if (globalSaveFileOverall.highscore < totalScore) globalSaveFileOverall.highscore = totalScore;
    }
    else if (gameType==ScoreGameTypes.LEVELS) {        

        const mapID = parseInt(`${this.getValue(ScoreDataDefault.MAP)}`);
        const levelID = parseInt(`${this.getValue(ScoreDataDefault.LEVEL)}`);
        const stars = parseInt(`${this.getValue(ScoreDataDefault.STARS)}`);
        const playtime = this.getValue("playtime") as number

        if (stars > 0) {
          /** MAP: Get / Check if map exists */
          const map = globalSaveFileSource.game.maps.find((map: any) => map.id === mapID);
          if (!map) return false;

          /** LEVEL: Adjust level */
          const level = map.levels.find((level: any) => level.id === levelID);
          if (level) {
            if (level.totalScore < totalScore) level.playTime = playtime
            if (level.totalScore < totalScore) level.totalScore = totalScore;
            if (level.highscore < totalScore) level.highscore = totalScore;
            if (level.stars < stars) level.stars = stars;
          }

          /** LEVEL: Add new level */
          else {
            map.levels.push({
              id: levelID,
              totalScore: totalScore,
              highscore: totalScore,
              stars: stars,
              playTime: playtime
            })
          }
          /** MAP: Calculate totalScore + totalStars for whole map */
          map.totalStars = map.totalScore = 0;
          map.levels.forEach((level: ISaveFileLevel) => {
            map.totalStars += level.stars;
            map.totalScore += level.totalScore;
          });

        }
    }

    globalSaveFile.update(globalSaveFileSource);
  }
}

const sessionSaveFile = new SessionSaveFile();
export default sessionSaveFile;