import Helper from "@/helpers/CommonFunctions";
import Setup from "@/helpers/Setup";
import { ScoreDataDefault, ScoreGameTypes, ScoreResultTypes } from "@/interfaces/ISessionFile";
import globalSaveFile from "./GlobalSaveFile";
import sessionSaveFile from "./SessionSaveFile";

class ScoreController {
  getResult() {
    const globalSaveFileSource = globalSaveFile.get();
    const gameType = sessionSaveFile.getType();

    /** SESSION: Sync with global savefile */
    sessionSaveFile.syncGlobalSaveFile();

    /** SCORE: Check if session is FINISHED / FAILED / PASSED */
    if (gameType == ScoreGameTypes.INFINITE) {
      sessionSaveFile.addEntry('resultType', ScoreResultTypes.FAILED);
      sessionSaveFile.updateValue('highscore', globalSaveFile.getHighscore());
    }
    else if (gameType == ScoreGameTypes.LEVELS) {
      const mapID = parseInt(`${sessionSaveFile.getValue('map')}`);
      const levelID = parseInt(`${sessionSaveFile.getValue('level')}`)
      const map = globalSaveFileSource.game.maps.find((map: any) => map.id === mapID);
      const stars = parseInt(`${sessionSaveFile.getValue(ScoreDataDefault.STARS)}`);
      const requiredStars = this.getRequiredStars(mapID, levelID);
      
      sessionSaveFile.updateValue('highscore', globalSaveFile.getHighscore(mapID, levelID));
      let resultType = (stars === 0 || requiredStars > 0) ? ScoreResultTypes.FAILED : ScoreResultTypes.PASSED;
      if (map && map.totalLevels === levelID) resultType = ScoreResultTypes.FINISHED;
      sessionSaveFile.addEntry('resultType', resultType);
      sessionSaveFile.addEntry('requiredStars', requiredStars > 0 ? requiredStars : 1);
    }

    /** SESSION: Format session data, so it can be used by Score View */
    const formattedForScoreView = this.getFormattedSession();

    /** SESSION: clear session */
    sessionSaveFile.clear();

    return formattedForScoreView;
  }

  getRequiredStars(mapID: number, levelID: number) {
    const globalSaveFileSource = globalSaveFile.get();
    const map = globalSaveFileSource.game.maps.find((map: any) => map.id === mapID);
    const totalStars = map?.totalStars || 0;
    const totalLevels = map?.totalLevels || 0;

    const difficulty = {
      start: Setup.getValue('settings.game.hasCustomDifficulty') ? parseFloat(Setup.getValue('settings.game.difficulty.start')) : 1.7,
      end: Setup.getValue('settings.game.hasCustomDifficulty') ? parseFloat(Setup.getValue('settings.game.difficulty.end')) : 1.2
    };
    const remapping = Helper.remapValue(levelID, 1, totalLevels, difficulty.start, difficulty.end);
    const starsNeeded = Math.floor(((levelID - 1) * 3) / remapping);

    return (starsNeeded - totalStars);
  }

  getFormattedSession() {
    const session = sessionSaveFile.getAll();
    const result: any = {};

    /** STAT: Filter stat items and format them to stat${i} starting from 1 */
    session.filter(item => item.isStat).map((item, index) => result[`stat${index + 1}`] = item.value);

    /** NON-STAT: Filter all non-stat items and format */
    session.filter(item => !item.isStat).map(item => result[item.key] = item.value);

    return result;
  }
}

const scoreController = new ScoreController();
export default scoreController;